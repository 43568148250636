// eslint-disable-next-line no-unused-vars
const moduleName = "templateGenerator";

const PDFDocument = require("pdfkit/js/pdfkit.standalone.js");
const blobStream = require("blob-stream");
const QRCode = require("qrcode");

function initialState() {
  return {
    rows: 12,
    cols: 9,
    pageX: 8.5,
    pageY: 11,
    marginX: 0.61,
    marginY: 0.64,
    // yFudge: 0.128,
    circleSize: 0.75, // diameter
    psPointsPerInch: 72,
    qrPoints: 21,
    addIndex: 0,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {},
  actions: {
    resetStore() {
      // doesn't need to do anything, stub for compatibility
    },
    getQRString(context, data) {
      return new Promise((resolve, reject) => {
        QRCode.toString(
          data,
          {
            type: "svg",
            errorCorrectionLevel: "H",
            margin: 0,
          },
          (err, string) => {
            if (err) {
              reject(err);
            } else {
              let temp = document.createElement("div");
              temp.innerHTML = string;
              let outStr = "";
              for (let child of temp.querySelectorAll("path")) {
                if (child.getAttribute("d").length > outStr.length) {
                  outStr = child.getAttribute("d");
                }
              }
              resolve(outStr);
            }
          }
        );
      });
    },
    async makePDF(
      context,
      { letterParts = [], template = false, yFudge = 0 } = {}
    ) {
      context.state.addIndex = 0;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          if (!letterParts.length) {
            template = true;
          }
          let doc = new PDFDocument({
            size: [
              context.state.pageX * context.state.psPointsPerInch,
              context.state.pageY * context.state.psPointsPerInch,
            ],
            margin: 0,
            autoFirstPage: false,
          });
          let stream = doc.pipe(blobStream());
          if (template) {
            for (let i = 0; i < context.state.rows * context.state.cols; i++) {
              // self.putQr(doc, i, true);
              await context.dispatch("putQR", {
                doc,
                template,
                index: i,
                yFudge,
              });
            }
          } else {
            for (let index of letterParts) {
              await context.dispatch("putQR", {
                doc,
                template,
                index,
                yFudge,
              });
            }
          }
          doc.end();
          stream.on("finish", () => {
            resolve(stream.toBlobURL("application/pdf"));
          });
        } catch (err) {
          reject(err);
        }
      });
    },
    async putQR(context, { doc, template, index, yFudge = 0 }) {
      let indexCopy = context.state.addIndex;
      context.state.addIndex++;
      let pageIndex = Math.floor(
        indexCopy / (context.state.rows * context.state.cols)
      );
      indexCopy -= pageIndex * context.state.rows * context.state.cols;
      let rowIndex = Math.floor(indexCopy / context.state.cols);
      let colIndex = indexCopy - rowIndex * context.state.cols;
      let xPad =
        context.state.marginX +
        (context.getters.xGap + context.state.circleSize) * colIndex +
        context.getters.circleRadius;
      let yPad =
        context.state.marginY +
        (context.getters.yGap + context.state.circleSize) * rowIndex +
        context.getters.circleRadius +
        yFudge;
      if (!rowIndex && !colIndex) {
        // it's the first item of a new page
        doc.addPage();
      }
      if (template) {
        doc
          .save()
          .lineWidth(1)
          .moveTo(0, 0)
          .circle(
            xPad * context.state.psPointsPerInch,
            yPad * context.state.psPointsPerInch,
            context.getters.circleRadius * context.state.psPointsPerInch
          )
          .stroke("black")
          .restore();
      } else {
        xPad -= context.getters.qrPad;
        yPad -= context.getters.qrPad;
        doc
          .save()
          .moveTo(0, 0)
          .translate(
            xPad * context.state.psPointsPerInch,
            yPad * context.state.psPointsPerInch
          )
          .scale(context.getters.qrScale * context.state.psPointsPerInch)
          .path(await context.dispatch("getQRString", "" + index))
          .stroke("black")
          .restore();
      }
    },
  },
  getters: {
    xGap(state) {
      return state.cols == 1
        ? 0
        : (state.pageX - state.circleSize * state.cols - state.marginX * 2) /
            (state.cols - 1);
    },
    yGap(state) {
      return state.rows == 1
        ? 0
        : (state.pageY - state.circleSize * state.rows - state.marginY * 2) /
            (state.rows - 1);
    },
    circleRadius(state) {
      return state.circleSize / 2;
    },
    qrScale(state) {
      return (state.circleSize / (2 ** 0.5 * state.qrPoints)) * 0.9;
    },
    qrPad(state, getters) {
      return (getters.qrScale * state.qrPoints) / 2;
    },
  },
};
